<template>
    <div>
        <div class="w-100 p-2 d-flex justify-content-end">
            <language-dropdown />
        </div>
        <div class="d-flex justify-content-center p-4">

            <div>
                <div class="d-flex justify-content-center">
                    <b-img
                        :src="appLogoImageName"
                        alt="logo"
                        style="width: 250px;"
                    />
                </div>
                <div class="mt-4 card text-center" style="font-size: 17px;">
                    <div v-if="messageType === 0">
                        <div>
                            {{ $t('confirm.link_expired') }}
                        </div>
                    </div>
                    <div v-if="messageType === 5">
                        <div>
                            {{$t('confirm.logged_in_as_other')}}.
                        </div>
                        <div class="mt-2">
                            <b-button variant="primary" @click="logout">{{ $t('login.logout') }}</b-button>
                        </div>
                    </div>
                    <div v-if="messageType === 1">
                        <div>
                            {{$t('confirm.successfully_added')}} <span style="font-weight: bold">{{ this.data.client_name}}. </span>
                            <div style="margin-top: 5px;">
                                {{$t('confirm.click_to_continue')}}
                            </div>
                        </div>

                        <div class="mt-2">
                            {{$t('confirm.choose_below')}}.
                        </div>

                        <div class="ml- mt-1">
                            <div class="d-flex" style="margin-bottom: 5px;">
                                <div class="d-flex" style="width: 200px;">
                                    <div>
                                        <feather-icon style="height: 19px; width: 19px; margin-right: 6px;" icon="BookIcon"/>
                                    </div>
                                    {{$t('print.message.changes_backlog')}}
                                </div>
                                <b-form-checkbox v-model="emailNotifications.changelog" switch />
                            </div>

                            <div class="d-flex"  style="margin-bottom: 5px;">
                                <div class="d-flex" style="width: 200px;">
                                    <div>
                                        <feather-icon style="height: 19px; width: 19px; margin-right: 6px;" icon="ShieldIcon"/>
                                    </div>
                                    {{$t('print.message.warning')}}
                                </div>
                                <b-form-checkbox v-model="emailNotifications.warning" switch />
                            </div>

                            <div class="d-flex"  style="margin-bottom: 5px;">
                                <div class="d-flex" style="width: 200px;">
                                    <div>
                                        <feather-icon style="height: 19px; width: 19px; margin-right: 6px;" icon="MailIcon"/>
                                    </div>
                                    {{$t('print.message.message')}}
                                </div>
                                <b-form-checkbox v-model="emailNotifications.messages" switch />
                            </div>

                            <div class="d-flex">
                                <div class="d-flex" style="width: 200px;">

                                    <div>
                                        <feather-icon style="height: 19px; width: 19px; margin-right: 6px;" icon="DollarSignIcon"/>
                                    </div>
                                    {{$t('print.message.finances')}}
                                </div>
                                <b-form-checkbox v-model="emailNotifications.finances" switch />
                            </div>
                        </div>
                    </div>

                    <div v-if="messageType >= 0 && messageType !== 5">
                        <b-button variant="primary" :disabled="loading" class="mt-4" @click="saveAndContinue">
                            <div style="font-size: 16px !important;">
                                {{$t('confirm.continue_to_app')}}
                                <b-spinner v-if="loading" class="ml-1" style="height: 15px; width: 15px"></b-spinner>
                            </div>
                        </b-button>
                    </div>
                    <div v-else-if="messageType === -1">
                        <div class="mb-2" style="font-size: 17px;">
                            {{$t('confirm.fetching')}}
                        </div>
                        <div class="d-flex justify-content-center">
                            <div class="loader" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
</template>

<script>

    import {BImg, BButton, BFormCheckbox, BSpinner} from 'bootstrap-vue'
    import { $themeConfig } from '@themeConfig'
    import * as Sentry from '@sentry/vue'
    import LanguageDropdown from '@/components/LanguageDropdown.vue'

    export default {
        components: {LanguageDropdown, BSpinner, BImg, BButton, BFormCheckbox},
        computed: {
            user() {
                return this.$store.getters['user/userData']
            }
        },
        data() {
            return {
                loading: false,
                messageType: -1,
                data: {
                    client_name: '',
                    user_id: ''
                },
                emailNotifications: {
                    changelog: false,
                    warning: true,
                    messages: false,
                    finances: true
                },
                link: ''
            }
        },
        setup() {
            // App Name
            const { appLogoImageName } = $themeConfig.app
            return {
                appLogoImageName
            }
        },
        methods: {
            logout() {
                this.$store.dispatch('user/logout')
                this.$router.push({name: 'login'})
            },
            async saveAndContinue() {
                try {
                    this.loading = true
                    await this.$http.post(`/api/client/v1/confirm/${  this.link}`, this.emailNotifications)
                    await this.$router.push('/')
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_user_accept'))
                } finally {
                    this.loading = false
                }
            },
            async loadData() {
                try {
                    const res = await this.$http.get(`/api/client/v1/confirm/validation/${  this.link}`)
                    this.messageType = 1
                    this.data = res.data

                    if (this.user && this.user.id !== this.data.user_id) {
                        this.messageType = 5
                    }

                } catch (err) {
                    this.messageType = 0
                    Sentry.captureException(err)
                }
            }
        },
        mounted() {
            this.link = this.$route.params.confirm_link


            this.loadData()
        }
    }

</script>

<style>
 .card{
   border-radius: 15px;
   padding: 25px 30px;
 }

 .loader{
   animation: l43 1s infinite linear;
   height: 30px;
   aspect-ratio: 2.5;
 }

 .dark-layout .loader{
   --_g: no-repeat radial-gradient(farthest-side, #aaaaaa 90%, rgba(194, 17, 17, 0));
   background:var(--_g), var(--_g), var(--_g), var(--_g);
   background-size: 20% 50%;
 }
 .light-layout .loader{
   --_g: no-repeat radial-gradient(farthest-side, #606060 90%, rgba(194, 17, 17, 0));
   background:var(--_g), var(--_g), var(--_g), var(--_g);
   background-size: 20% 50%;
 }

 @keyframes l43 {
   0%     {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
   16.67% {background-position: calc(0*100%/3) 0   ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
   33.33% {background-position: calc(0*100%/3) 100%,calc(1*100%/3) 0   ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
   50%    {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 100%,calc(2*100%/3) 0   ,calc(3*100%/3) 50% }
   66.67% {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 100%,calc(3*100%/3) 0   }
   83.33% {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 100%}
   100%   {background-position: calc(0*100%/3) 50% ,calc(1*100%/3) 50% ,calc(2*100%/3) 50% ,calc(3*100%/3) 50% }
 }

</style>